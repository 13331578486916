import React, { useState, useEffect, useRef } from "react";
import { View, Text, Image, ScrollView } from "react-native-web";
import Timer from "./Timer";
import logo from "../assets/images/logo.png";
import bg from "../assets/images/bg.png";

// Update the imports to use require
const videos = {
	drDadoo: require("../components/videos/DrDadoo.mp4"),
	drKnight: require("../components/videos/DrKnight.mp4"),
	drMalinga: require("../components/videos/DrMalinga.mp4"),
	drMathibe: require("../components/videos/DrMathibe.mp4"),
	drSibisi: require("../components/videos/DrSibisi.mp4"),
	drVenter: require("../components/videos/DrVenter.mp4"),
	drZulu: require("../components/videos/DrZulu.mp4"),
};

const allQuestionSets = [
	{
		videoFile: {
			src: videos.drZulu,
			title: "Dr Zulu",
		},

		questions: [
			{
				question: "What is Step 3?",
				options: [
					"Moisturise Daily",
					"Moisturise once a week",
					"Moisturise 3 times a week",
				],
				answer: "Moisturise Daily",
			},
			{
				question:
					"What are the 2 featured products at the end of the video?",
				options: [
					"CeraVe Hydrating Cleanser and Facial Moisturising lotion SPF 50",
					"CeraVe Acne Control Serum and Blemish Control Cleanser",
					"CeraVe Acne Control Serum and Hydrating Cleanser",
				],
				answer: "CeraVe Hydrating Cleanser and Facial Moisturising lotion SPF 50",
			},
			{
				question: "How many tips are shared in the video?",
				options: ["5", "3", "4"],
				answer: "5",
			},
			{
				question:
					"The 5 easy tips shared by Dr Zulu are for __________ skin?",
				options: ["Dry", "Oily", "Sensitive"],
				answer: "Dry",
			},
			{
				question: "What is Step 1?",
				options: ["Cleanse Carefully", "Exfoliate", "Moisturise"],
				answer: "Cleanse Carefully",
			},
			{
				question:
					"Fill in the blanks – Hydrate ____________ and _________?",
				options: [
					"Inside and Out",
					"morning and night",
					"quickly and carefully",
				],
				answer: "Inside and Out",
			},
		],
	},
	{
		videoFile: {
			src: videos.drKnight,
			title: "Dr Knight",
		},

		questions: [
			{
				question:
					"Is it true that ceramides are naturally found in the skin barrier?",
				options: ["True", "False"],
				answer: "True",
			},
			{
				question: "What product is highlighted in the video?",
				options: [
					"CeraVe Moisturising Cream",
					"CeraVe Blemish Control Cleanser",
					"CeraVe Hydrating Cleanser",
				],
				answer: "CeraVe Moisturising Cream",
			},
			{
				question:
					"List the name of skin-identical ceramides in the video.",
				options: [
					"Ceramide 1, 3 and 6-II",
					"Ceramide 2, 6 and 6-8",
					"Ceramide 1, 4 and 6-10",
				],
				answer: "Ceramide 1, 3 and 6-II",
			},
			{
				question:
					"Is it true that ceramide levels in the skin barrier get reduced over time?",
				options: ["True", "False"],
				answer: "True",
			},
		],
	},

	{
		videoFile: {
			src: videos.drDadoo,
			title: "Dr Dadoo",
		},

		questions: [
			{
				question:
					"True or False; Do low ceramide levels weaken the skin barrier?",
				options: ["True", "False"],
				answer: "True",
			},
			{
				question: "What product is featured at the end of the video?",
				options: [
					"CeraVe Hydrating Cleanser",
					"CeraVe Moisturising Cream",
					"CeraVe Blemish Control Cleanser",
				],
				answer: "CeraVe Hydrating Cleanser",
			},
			{
				question:
					"How many essential ceramides are found in CeraVe products?",
				options: ["3", "4", "2"],
				answer: "3",
			},
			{
				question:
					"Fill in the blanks: ______ ceramide levels ________ the skin barrier?",
				options: [
					"Low and Weaken",
					"High and Weaken",
					"High and Strengthen",
				],
				answer: "Low and Weaken",
			},
			{
				question:
					"When the skin barrier is weakened what escapes from the skin?",
				options: ["Moisture", "Elasticity", "Air"],
				answer: "Moisture",
			},
			{
				question:
					"Fill in the blanks: Using a ______ rich ________ helps to _______ and protect the skin barrier.",
				options: [
					"Ceramide, cleanser, and replenish",
					"Ceramide, cleanser, and strengthen",
					"Ceramide, serum, and nourish",
				],
				answer: "Ceramide, cleanser, and replenish",
			},
		],
	},

	{
		videoFile: {
			src: videos.drMalinga,
			title: "Dr Malinga",
		},

		questions: [
			{
				question:
					"True or False: Ceramides are oils that make up 50% of your skin?",
				options: ["True", "False"],
				answer: "True",
			},
			{
				question: "What product is highlighted in the video?",
				options: [
					"CeraVe Moisturising Cream",
					"CeraVe Hydrating Cleanser",
					"CeraVe Moisturising Lotion",
				],
				answer: "CeraVe Moisturising Cream",
			},
			{
				question: "Name the Health Care Practitioner in the video:",
				options: ["Dr Malinga", "Dr Knight", "Dr Venter"],
				answer: "Dr Malinga",
			},
			{
				question:
					"Ceramides are oils that make up 50% of your skin, __________ the _________________ and __________.",
				options: [
					"supporting the skin barrier and retaining moisture",
					"nourishing the skin barrier and reducing wrinkles",
					"replenishing the skin barrier and retaining ceramides",
				],
				answer: "supporting the skin barrier and retaining moisture",
			},
			{
				question:
					"How do you replenish the ceramide level in your skin barrier?",
				options: [
					"Use a moisturiser with Skin identical ceramides",
					"Use a serum with skin identical ceramides",
					"Use a moisturiser with Skin similar ceramides",
				],
				answer: "Use a moisturiser with Skin identical ceramides",
			},
		],
	},

	{
		videoFile: {
			src: videos.drSibisi,
			title: "Dr Sibisi",
		},

		questions: [
			{
				question:
					"True or False: Ceramides are oils that make up 50% of your skin?",
				options: ["True", "False"],
				answer: "True",
			},
			{
				question: "What product is highlighted in the video?",
				options: [
					"CeraVe Moisturising Cream",
					"CeraVe Hydrating Cleanser",
					"CeraVe Moisturising Lotion",
				],
				answer: "CeraVe Moisturising Cream",
			},
			{
				question: "Name the Health Care Practitioner in the video:",
				options: ["Dr Malinga", "Dr Knight", "Dr Venter"],
				answer: "Dr Malinga",
			},
			{
				question:
					"Ceramides are oils that make up 50% of your skin, __________ the _________________ and __________.",
				options: [
					"supporting the skin barrier and retaining moisture",
					"nourishing the skin barrier and reducing wrinkles",
					"replenishing the skin barrier and retaining ceramides",
				],
				answer: "supporting the skin barrier and retaining moisture",
			},
			{
				question:
					"How do you replenish the ceramide level in your skin barrier?",
				options: [
					"Use a moisturiser with Skin identical ceramides",
					"Use a serum with skin identical ceramides",
					"Use a moisturiser with Skin similar ceramides",
				],
				answer: "Use a moisturiser with Skin identical ceramides",
			},
		],
	},

	{
		videoFile: {
			src: videos.drVenter,
			title: "Dr Venter",
		},

		questions: [
			{
				question: "What is the key Hashtag in the video?",
				options: ["#GetCERAous", "#GetCERAmide", "#GetCERAVE"],
				answer: "#GetCERAous",
			},
			{
				question: "What product is featured in the video?",
				options: [
					"CeraVe Advanced Ointment",
					"CeraVe Moisturising Cream",
					"CeraVe Acne Control Serum",
				],
				answer: "CeraVe Advanced Ointment",
			},
			{
				question: "Name the Health Care Practitioner in the video:",
				options: ["Dr Venter", "Dr Knight", "Dr Dadoo"],
				answer: "Dr Venter",
			},
			{
				question: "Dr Venter addresses which concern in the video?",
				options: ["Dry skin", "Acne", "Blemish Control"],
				answer: "Dry skin",
			},
			{
				question:
					"Complete the sentence: Dry skin happens when your skin __________________",
				options: [
					"Loses too much moisture",
					"Is not cleansed correctly",
					"Is exposed to sun",
				],
				answer: "Loses too much moisture",
			},
			{
				question: "What do occlusives create?",
				options: [
					"Protective barrier over your skin",
					"Clear skin",
					"Skin protection from the sun",
				],
				answer: "Protective barrier over your skin",
			},
		],
	},

	{
		videoFile: {
			src: videos.drMathibe,
			title: "Dr Mathibe",
		},

		questions: [
			{
				question:
					"What are the 2 factors Dr Mathibe mentions in the video that cause harm to the Skin barrier?",
				options: [
					"Stress and Weather",
					"Stress and Germs",
					"Weather and Germs",
				],
				answer: "Stress and Weather",
			},
			{
				question:
					"Fill in the blanks: A Moisturiser that contains ____________ and ________ can help replenish your skin barrier.",
				options: [
					"3 essential ceramides and Mve Technology",
					"4 essential ceramides and Mve Technology",
					"2 essential ceramides and Mve Technology",
				],
				answer: "3 essential ceramides and Mve Technology",
			},
			{
				question: "What product is highlighted in the video?",
				options: [
					"CeraVe Moisturising Cream",
					"CeraVe Serum for dry Skin",
					"CeraVe Resurfacing Retinol Serum",
				],
				answer: "CeraVe Moisturising Cream",
			},
			{
				question: "Name the Health Care Practitioner in the video:",
				options: ["Dr Mathibe", "Dr Venter", "Dr Dadoo"],
				answer: "Dr Mathibe",
			},
		],
	},

	// Add more sets as needed
];

// Update the shuffleArray function to be more robust
const shuffleArray = (array) => {
	if (!Array.isArray(array) || array.length === 0) return [];

	const shuffled = [...array];
	for (let i = shuffled.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
	}
	return shuffled;
};

// Add a new function to shuffle questions with their options
const prepareQuestions = (questions) => {
	return questions.map((question) => {
		// Create a copy of the question object
		const shuffledQuestion = { ...question };
		// Shuffle the options array
		shuffledQuestion.options = shuffleArray(question.options);
		return shuffledQuestion;
	});
};

// Add this function near the top of the file, after the imports
const loadVideoWithFallback = async (videoUrl) => {
	try {
		// Try to fetch from cache first
		const cache = await caches.open("cerave-video-cache-v1");
		const cachedResponse = await cache.match(videoUrl);

		if (cachedResponse) {
			return URL.createObjectURL(await cachedResponse.blob());
		}

		// If not in cache, fetch and cache it
		const response = await fetch(videoUrl);
		const blob = await response.blob();
		await cache.put(videoUrl, new Response(blob));
		return URL.createObjectURL(blob);
	} catch (error) {
		console.error("Error loading video:", error);
		return videoUrl; // Fallback to original URL if caching fails
	}
};

const Game = ({ gameOver, onGameEnd, resetGame, passTimeStartToParent }) => {
	const [shuffledQuestions, setShuffledQuestions] = useState([]);
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [score, setScore] = useState(0);
	const [currentVideoUrl, setCurrentVideoUrl] = useState("");
	const [isTimerStarted, setIsTimerStarted] = useState(false);
	const videoRef = useRef(null);
	const [isVideoLoading, setIsVideoLoading] = useState(true);
	const [videoError, setVideoError] = useState(false);
	const [showSplash, setShowSplash] = useState(true);
	const [showVideo, setShowVideo] = useState(false);
	const [showQuestions, setShowQuestions] = useState(false);
	const [isTimerRunning, setIsTimerRunning] = useState(false);
	const [showCelebration, setShowCelebration] = useState(false);

	useEffect(() => {
		const randomSet =
			allQuestionSets[Math.floor(Math.random() * allQuestionSets.length)];
		// First shuffle and prepare the questions with shuffled options
		const preparedQuestions = prepareQuestions(randomSet.questions);
		// Then shuffle the order of questions
		setShuffledQuestions(shuffleArray(preparedQuestions));

		// Reset video states when loading new video
		setIsVideoLoading(true);
		setVideoError(false);

		// Load video with caching
		loadVideoWithFallback(randomSet.videoFile.src)
			.then((videoUrl) => {
				setCurrentVideoUrl(videoUrl);
			})
			.catch((error) => {
				console.error("Error loading video:", error);
				setVideoError(true);
				setIsVideoLoading(false);
			});
	}, [resetGame]);

	useEffect(() => {
		if (resetGame) {
			setCurrentQuestion(0);
			setScore(0);
			setIsTimerStarted(false);
		}
	}, [resetGame]);

	useEffect(() => {
		return () => {
			// Clean up object URLs when component unmounts
			if (currentVideoUrl.startsWith("blob:")) {
				URL.revokeObjectURL(currentVideoUrl);
			}
		};
	}, [currentVideoUrl]);

	const handleAnswer = (selectedOption) => {
		if (shuffledQuestions[currentQuestion].answer === selectedOption) {
			setScore((prevScore) => prevScore + 1);
		}

		if (currentQuestion < shuffledQuestions.length - 1) {
			setCurrentQuestion((prevQuestion) => prevQuestion + 1);
		} else {
			const finalScore =
				shuffledQuestions[currentQuestion].answer === selectedOption
					? score + 1
					: score;
			if (finalScore === shuffledQuestions.length) {
				setShowCelebration(true);
				setTimeout(() => {
					setShowCelebration(false);
					onGameEnd();
				}, 3000); // Show celebration for 3 seconds
			} else {
				onGameEnd();
			}
		}
	};

	const handleVideoEnded = () => {
		setShowVideo(false);
		setShowQuestions(true);
		setIsTimerRunning(true);
		passTimeStartToParent(true);
	};

	const startTimer = () => {
		setIsTimerStarted(true);
		setShowVideo(true); // Show video when Start is clicked
		if (videoRef.current) {
			videoRef.current.play();
		}
	};

	const handleVideoError = () => {
		setVideoError(true);
		setIsVideoLoading(false);
		console.error("Video failed to load");
	};

	const handleVideoLoad = () => {
		setIsVideoLoading(false);
		setVideoError(false);
	};

	const handleTimeUp = () => {
		onGameEnd();
		setIsTimerRunning(false);
	};

	// Add this modal style object at the top of your component
	const modalStyle = {
		position: "fixed",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		// backgroundColor: "#C7E7F8",
		backgroundColor: "#FFF",
		padding: "40px",
		borderRadius: "15px",
		boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
		zIndex: 1000,
		width: "90%",
		maxWidth: "500px",
		textAlign: "center",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		border: "2px solid #0B84D9",
	};

	const buttonStyle = {
		padding: "12px 30px",
		fontSize: "18px",
		cursor: "pointer",
		backgroundColor: "#0B84D9",
		color: "white",
		border: "none",
		borderRadius: "8px",
		marginTop: "25px",
		transition: "all 0.3s ease",
		fontWeight: "bold",
		boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
		width: "200px",
	};

	// Add splash screen click handler
	const handleSplashClick = () => {
		setShowSplash(false);
	};

	// Add this style object near other style definitions
	const skipButtonStyle = {
		position: "absolute",
		bottom: "40px",
		right: "20px",
		padding: "10px 20px",
		backgroundColor: "rgba(11, 132, 217, 0.2)",
		color: "white",
		border: "none",
		borderRadius: "8px",
		cursor: "pointer",
		fontSize: "16px",
		fontWeight: "500",
		zIndex: 1001,
		transition: "all 0.3s ease",
	};

	return (
		<View
			style={{
				textAlign: "center",
				fontFamily: "Arial, sans-serif",
				backgroundColor: "#F5F5F5",
				height: "100vh",
				width: "100vw",
				position: "fixed",
				top: 0,
				left: 0,
				overflow: "hidden",
			}}>
			{/* Celebration Modal */}
			{showCelebration && (
				<View
					style={{
						position: "fixed",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: "rgba(0, 0, 0, 0.8)",
						zIndex: 9999,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						animation: "fadeIn 0.5s ease-in-out",
					}}>
					<View
						style={{
							backgroundColor: "#FFF",
							padding: "40px",
							borderRadius: "20px",
							textAlign: "center",
							maxWidth: "500px",
							width: "90%",
							boxShadow: "0 0 30px rgba(11, 132, 217, 0.3)",
							border: "3px solid #0B84D9",
							animation: "scaleIn 0.5s ease-in-out",
						}}>
						<Text
							style={{
								fontSize: "36px",
								fontWeight: "bold",
								color: "#0B84D9",
								marginBottom: "20px",
							}}>
							🎉 Congratulations! 🎉
						</Text>
						<Text
							style={{
								fontSize: "24px",
								color: "#333",
								marginBottom: "20px",
								lineHeight: "1.4",
							}}>
							Perfect Score! You've achieved 100%
						</Text>
						<Text
							style={{
								fontSize: "18px",
								color: "#666",
								fontStyle: "italic",
							}}>
							You're a CeraVe Expert!
						</Text>
					</View>
				</View>
			)}

			{/* Splash Screen */}
			{showSplash && (
				<View
					onClick={handleSplashClick}
					style={{
						position: "fixed",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						zIndex: 9999,
						backgroundColor: "#FFF",
						cursor: "pointer",
					}}>
					<Image
						source={bg}
						style={{
							width: "100%",
							height: "100%",
							objectFit: "contain",
							backgroundColor: "#FFF",
						}}
						alt='Background'
					/>
				</View>
			)}

			{gameOver ? (
				<View style={modalStyle}>
					<Text
						style={{
							fontSize: "32px",
							fontWeight: "bold",
							color: "#0B84D9",
							marginBottom: "20px",
						}}>
						Game Over!
					</Text>
					<Text
						style={{
							fontSize: "24px",
							margin: "15px 0",
							color: "#333",
							fontWeight: "500",
						}}>
						Your Score: {score} / {shuffledQuestions.length}
					</Text>
					<button
						onClick={() => window.location.reload()}
						style={buttonStyle}
						onMouseOver={(e) => {
							e.target.style.backgroundColor = "#096BB5";
							e.target.style.transform = "translateY(-2px)";
							e.target.style.boxShadow =
								"0 6px 8px rgba(0, 0, 0, 0.2)";
						}}
						onMouseOut={(e) => {
							e.target.style.backgroundColor = "#0B84D9";
							e.target.style.transform = "translateY(0)";
							e.target.style.boxShadow =
								"0 4px 6px rgba(0, 0, 0, 0.1)";
						}}>
						Play Again
					</button>
				</View>
			) : (
				<View style={{ height: "100%", width: "100%" }}>
					{!isTimerStarted ? (
						<View style={modalStyle}>
							<Image
								source={logo}
								style={{
									width: 180,
									height: 80,
									objectFit: "contain",

									marginBottom: "30px",
								}}
								alt='CeraVe Logo'
							/>
							<Text
								style={{
									fontSize: "24px",
									color: "#333",
									marginBottom: "20px",
									fontWeight: "500",
									lineHeight: "1.4",
								}}>
								Ready to test your CeraVe knowledge?
							</Text>
							<button
								onClick={startTimer}
								style={buttonStyle}
								onMouseOver={(e) => {
									e.target.style.backgroundColor = "#096BB5";
									e.target.style.transform =
										"translateY(-2px)";
									e.target.style.boxShadow =
										"0 6px 8px rgba(0, 0, 0, 0.2)";
								}}
								onMouseOut={(e) => {
									e.target.style.backgroundColor = "#0B84D9";
									e.target.style.transform = "translateY(0)";
									e.target.style.boxShadow =
										"0 4px 6px rgba(0, 0, 0, 0.1)";
								}}>
								Start Quiz
							</button>
						</View>
					) : (
						<>
							{showVideo && (
								<View
									style={{
										position: "fixed",
										top: 0,
										left: 0,
										right: 0,
										bottom: 0,
										backgroundColor: "#000",
										zIndex: 1000,
									}}>
									<video
										ref={videoRef}
										src={currentVideoUrl}
										controls
										preload='auto'
										onError={handleVideoError}
										onLoadedData={handleVideoLoad}
										onEnded={handleVideoEnded}
										style={{
											width: "100%",
											height: "100%",
											objectFit: "contain",
										}}>
										Your browser does not support the video
										tag.
									</video>

									{/* Add Skip Video button */}
									<button
										onClick={handleVideoEnded}
										style={skipButtonStyle}
										onMouseOver={(e) => {
											e.target.style.backgroundColor =
												"rgba(11, 132, 217, 1)";
											e.target.style.transform =
												"translateY(-2px)";
										}}
										onMouseOut={(e) => {
											e.target.style.backgroundColor =
												"rgba(11, 132, 217, 0.8)";
											e.target.style.transform =
												"translateY(0)";
										}}>
										Skip Video
									</button>

									{isVideoLoading && (
										<View
											style={{
												position: "absolute",
												top: "50%",
												left: "50%",
												transform:
													"translate(-50%, -50%)",
												color: "#fff",
											}}>
											<Text>Loading video...</Text>
										</View>
									)}
								</View>
							)}

							{showQuestions && (
								<View
									style={{
										width: "100%",
										height: "100%",
										position: "relative",
										overflow: "hidden",
									}}>
									{/* Logo in top left */}
									<Image
										source={logo}
										style={{
											width: 120,
											height: 50,
											objectFit: "contain",
											position: "absolute",
											top: "20px",
											left: "20px",
											zIndex: 2,
										}}
										alt='CeraVe Logo'
									/>

									{/* Timer in top right */}
									<View
										style={{
											position: "absolute",
											top: "20px",
											right: "20px",
											zIndex: 2,
										}}>
										<Timer
											startTime={60}
											onTimeUp={handleTimeUp}
											isRunning={isTimerRunning}
											key={resetGame}
										/>
									</View>

									{/* Scrollable Question Container */}
									<ScrollView
										style={{
											width: "100%",
											height: "calc(100% - 140px)",
											marginTop: "80px",
											paddingBottom: "60px",
										}}
										contentContainerStyle={{
											display: "flex",
											flexGrow: 1,
											justifyContent: "center",
											alignItems: "center",
											padding: "20px",
										}}>
										<View
											style={{
												width: "100%",
												maxWidth: "800px",
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												gap: "20px",
											}}>
											<Text
												style={{
													fontSize:
														"clamp(20px, 3vw, 28px)",
													fontWeight: "700",
													color: "#333",
													lineHeight: "1.4",
													textAlign: "center",
													width: "100%",
													padding: "0 10px",
												}}>
												{
													shuffledQuestions[
														currentQuestion
													]?.question
												}
											</Text>

											<View
												style={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
													gap: "10px",
													width: "100%",
													maxWidth: "600px",
													padding: "0 10px",
												}}>
												{shuffledQuestions[
													currentQuestion
												]?.options.map(
													(option, index) => (
														<button
															key={index}
															onClick={() =>
																handleAnswer(
																	option,
																)
															}
															style={{
																display:
																	"block",
																width: "100%",
																padding:
																	"clamp(12px, 1.5vh, 20px)",
																fontSize:
																	"clamp(14px, 1.5vw, 18px)",
																fontWeight:
																	"500",
																cursor: "pointer",
																backgroundColor:
																	"#f0f0f0",
																border: "1px solid #ccc",
																borderRadius:
																	"8px",
																transition:
																	"all 0.3s ease",
																color: "#333",
																textAlign:
																	"center",
															}}
															onMouseOver={(
																e,
															) => {
																e.target.style.backgroundColor =
																	"#e0e0e0";
																e.target.style.transform =
																	"translateY(-2px)";
																e.target.style.boxShadow =
																	"0 4px 8px rgba(0, 0, 0, 0.1)";
															}}
															onMouseOut={(e) => {
																e.target.style.backgroundColor =
																	"#f0f0f0";
																e.target.style.transform =
																	"translateY(0)";
																e.target.style.boxShadow =
																	"none";
															}}>
															{option}
														</button>
													),
												)}
											</View>
										</View>
									</ScrollView>
								</View>
							)}
						</>
					)}
				</View>
			)}

			<View
				style={{
					position: "absolute",
					bottom: 0,
					left: 0,
					right: 0,
					padding: "10px",
					backgroundColor: "rgba(255, 255, 255, 0.9)",
					borderTop: "1px solid #eee",
					textAlign: "center",
				}}>
				<Text
					style={{
						fontSize: "12px",
						color: "#666",
						fontStyle: "italic",
					}}>
					CeraVe Quiz by <strong>tetrice</strong> ©{" "}
					{new Date().getFullYear()} - v1.1.3
				</Text>
			</View>
		</View>
	);
};

// Add these animations to your index.css file
const styles = `
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes scaleIn {
	from {
		transform: scale(0.8);
		opacity: 0;
	}
	to {
		transform: scale(1);
		opacity: 1;
	}
}
`;

export default Game;
